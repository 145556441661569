<template>
    <div class="prefooter-blocks-large">
        <b-row>
            <b-col sm="12" md="6" xl="3" class="block-wrap">
                <div class="block">
                    <div>
                        <Check />
                        <strong>Conferma immediata</strong>
                        <p>
                            Prenoti viaggi con pochi click e in tempo reale. Nessun preventivo, nessun voucher.
                        </p>
                    </div>
                </div>
            </b-col>
            <b-col sm="12" md="6" xl="3" class="block-wrap">
                <div class="block">
                    <div>
                        <DoubleCheck />
                        <strong>Double check</strong>
                        <p>
                            Dopo la conferma online verifichiamo di nuovo la prenotazione qualche giorno prima del tuo arrivo.
                        </p>
                    </div>
                </div>
            </b-col>
            <b-col sm="12" md="6" xl="3" class="block-wrap">
            <div class="block">
                <div>
                    <HeartLarge />
                        <strong>White list</strong>
                        <p>
                            La nostra tecnologia e le nostre persone lavorano ogni giorno per proporti solo strutture di provata qualità e professionalità.
                        </p>
                    </div>
                </div>
            </b-col>
            <b-col sm="12" md="6" xl="3" class="block-wrap">
                <div class="block">
                    <div>
                        <Headset />
                        <strong>Assistenza interna</strong>
                        <p>
                            Ti assistiamo con persone che potrai chiamare per nome. Nessun call center esterno o robot.
                        </p>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>    
</template>

<script>
import Check from '../../svg/check'
import DoubleCheck from '../../svg/double-check'
import HeartLarge from '../../svg/heart-large'
import Headset from '../../svg/headset'
export default {
    name: 'prefooter-white',
    components:
    {
        Check, DoubleCheck, HeartLarge, Headset
    }
}
</script>

<style lang="scss">
    
    
    
    

    .prefooter-blocks-large
    {
        margin:4rem auto;
        $block-pad: $grid-gutter-width/2;
        .block-wrap
        {
        //&:not(:last-child)
        //{
            margin-bottom:2rem;
        //}
        }
        .block
        {
            height:100%;

            &>div
            {
                position: relative;
                z-index: 10;
                background:$white;
                border-radius:$border-radius;
                padding:$block-pad;
                margin-bottom:$block-pad;
                display:flex;
                flex-direction: column;
                align-items: center;
                text-align:center;
                height:100%;
            }

            position:relative;
            background-color:transparent;
            &:before
            {
                content:"";
                position:absolute;
                left:0;
                bottom:0;
                //@include customize-background-color("secondary");
                background: $white;
                height:calc(100% + 8px);
                width:calc(100% + 6px);
                z-index: 1;
                border-top-right-radius: 100%;
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                transform:skew(-2deg) translateY(8px) translateX(-1%);
            }
            &:after
            {
                content:"";
                position:absolute;
                left:0;
                bottom:0;
                height:100%;
                width:100%;
                z-index: 2;
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;

            }             
        }


        svg
        {
            margin-bottom:2rem;
            path
            {
                fill: $tertiary;
                @if($customize)
                {
                    @include customize-fill(text);
                }
            }
        }
        strong
        {
            margin-bottom:0;
            font-size:20px
        }
        p
        {
            max-width: 100%;
            font-size:16px;
            margin-bottom:0;
        }
    }
</style>